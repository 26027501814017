import { AIComm } from "./AIComm";
import { ChatSettingsInferenceTemplate, Message, Model, ModelSets, TextReply } from "./Model";
import { ZService } from "./ZSettingsfn";
import { getBrowserInfo } from "global/utils";
import { eventBus } from "global/event-bus";
import { BrowserCommExtra } from "./BrowserCommExtra";

declare global {
  //interface Window { ai }
}

export class BrowserComm extends AIComm {
  isJson: boolean;
  token = '';
  session: any = null;

  constructor(name, urlBase, model, key, isJson = true) {
    super(name, urlBase, model, key);
    this.isJson = isJson;
  }

  async getAuthHeader(model: Model = null): Promise<{ key: string; value: string }> {
    return null;
  }

  getURLwithSuffix(url, model) {
    if (url.endsWith('/')) {
      url = url.substring(0, url.length - 1);
    }

    url = 'js:window.ai';

    return url;
  }

  getValueByPath(data, path) {
    let result = data;
    for (const key of path) {
      if (result != null && key in result) {
        result = result[key];
      } else {
        return undefined; // Path does not exist
      }
    }
    return result;
  }

  async getModelsClass(service: ZService = null): Promise<Model[]> {
    if (this.wasCalled || !ModelSets.instance) {
      return service ? service.models : [];
    }

    this.wasCalled = true;
    const json = this.browserModels() as any; // await Comm.getAllModelsJson();
    const models = Model.loadModelsFlat(json.browser, this, service);
    models.map(m => {
      m.serviceUid = service.uid;
    });
    // this.addInServiceModels(models, service);
    this.isConnected = true;

    service.useApiKey = false;
    service.aiTemplate = 'promptapi';
    const browser = getBrowserInfo();
    if (browser === 'Chrome') {
      this.apiKey = 'nano';
    }

    return models;
  }

  browserModels() {
    return BrowserCommExtra.sBrowserModels();
  }

  async prepSession() {
    if (this.session === null) {
      try {
        let result = await (window as any).ai.assistant.capabilities();
        if (result.available !== 'readily') {
          throw new Error("Cannot create model now - " + result.available);
        }
        this.session = await (window as any).ai.assistant.create();
      } catch (error) {
        throw new Error("Cannot create session now - " + error);
      }
    }
  }

  async callDirect(instruction: string, infTemp: ChatSettingsInferenceTemplate | null, model: Model): Promise<string> {
    const useTemplate = false;

    await this.prepSession();

    const prompt = useTemplate ? '<|user|>' + instruction + '<|assistant|>' : instruction;

    let result = "";
    try {
      let stream = this.session.promptStreaming(prompt);
      for await (const chunk of stream) {
        result += chunk;
      }
    } catch (error) {
      console.error("Error during promptStreaming: ", error);
    }

    return result;
  }

  //Upcall:01
  async callX(instruction: string, msgs: Message[], infTemp: ChatSettingsInferenceTemplate | null, temperature: number, update: any, model: Model, chatId: string = null): Promise<string> {
    if ((!ModelSets.aiTemplates) || ModelSets.aiTemplates == null) {
      await ModelSets.postLoad();
    }

    //const service = ModelSets.getService(model);
    //const browser = getBrowserInfo();
    const defaultTemplate = 'none';
    const templateName = infTemp && infTemp.template != 'default' ? infTemp.template : defaultTemplate;
    const hasTemplate = templateName !== 'none';
    const template = await ModelSets.formInferenceTemplate(infTemp, defaultTemplate);

    const newMessages = msgs.map(m => {
      return {
        role: m.type,
        type: 'text',
        text: m.message
      };
    });

    if (instruction) {
      newMessages.unshift({ role: "system", type: 'text', text: instruction });
    }

    let prompt = '';

    newMessages.map(m => {
      switch (m.role) {
        case 'system':
          prompt += template.preInst + m.text + template.postInst;
          break;
        case 'user':
          prompt += template.preUser + m.text + template.postUser;
          break;
        case 'assistant':
          prompt += template.preAsst + m.text + template.postAsst;
          break;
      }

      if (!hasTemplate) {
        prompt += '\n';
      }
    });

    prompt += template.leadingAsst;

    try {
      const startTime = new Date().getTime();
      var prevData = { len: 0 };

      await this.prepSession();
      let stream = this.session.promptStreaming(prompt);

      for await (const chunk of stream) {
        await this.parseAndSend2(chunk, update, model, startTime, prevData);
      }

    } catch (error) {
      const txtResp = TextReply.create(error.message, true);
      update(txtResp, model, true);
      eventBus.emit('ccx-error', { error: error.message, isDone: true, uid: chatId });
      console.error("Failed to open WebSocket connection: ", error);
    }
    return "";
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async parseAndSend2(response, update, model, startTime = 0, prevData: any = null): Promise<boolean> {
    var doContinue = true;

    const parser = (response) => {
      var resp = response;
      if (prevData && prevData.len && prevData.len <= resp.length) {
        resp = resp.substring(prevData.len);
      }
      prevData.len += resp.length;
      if (resp) {
        const endTime = new Date().getTime();
        const ms = endTime - startTime;

        const textChunk = resp;

        const reply = { text: textChunk, isDone: false, ms: ms };
        if (reply) {
          doContinue = update(reply, model);
        }
      }
    };

    parser(response);

    if (!doContinue) {
      const endTime = new Date().getTime();
      const ms = endTime - startTime;
      update({ isDone: true, ms: ms }, model)
    }

    return doContinue;
  }

  parseTextReply(text: string, model: Model): TextReply {
    return { text: text, isDone: false };
  }
}
